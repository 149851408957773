/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App as AntdApp } from "antd";
import App from "App";

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";
import LocaleProvider from "antd/es/locale";
import locale from "antd/lib/locale/ko_KR"; // Import the Korean locale for Antd

import dayjs from "dayjs";
import "dayjs/locale/ko"; // Import the dayjs Korean locale

// Set the global locale for dayjs to Korean
dayjs.locale("ko");

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <LocaleProvider locale={locale}>
        <AntdApp>
          <App />
        </AntdApp>
      </LocaleProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
