// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Card, DatePicker, Statistic, Table } from "antd";
import { IGetDash } from "apis/response";
import Apis from "apis/remotes";
import MDButton from "components/MDButton";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";

const dashData = {
  totalPayment: 0, // 총 충전 금액
  totalPaymentFee: 0, // 총 충전 수수료
  totalParentPoint: 0, // 남은 보호자 충전 포인트
  totalSettlement: 0, // 총 환전 금액
  totalSettlementFee: 0, // 총 환전 수수료
  totalChildPoint: 0, // 아이 남은 포인트
  monthPayment: 0, // 이번달 충전금액
  monthPaymentFee: 0, // 이번달 충전 수수료 합산
  monthSettlement: 0, // 이번달 환전금액
  monthSettlementFee: 0, // 이번달 환전 수수료 합산
  manualSettlementCount: 0, //수동 정산 대상 인원 수
  manualSettlementAmount: 0, //수동 정산 남은 금액
  totalAlbumAmount: 0, // 총 판매 금액 합산
  totalAlbumAmountFee: 0, // 총 판매 수수료 합산
  monthAlbumAmount: 0, // 이번달 판매 금액 합산
  monthAlbumAmountFee: 0, // 이번달 판매 수수료 합산
};

const columns = [
  {
    title: "총 충전 금액",
    dataIndex: "totalPayment",
    key: "totalPayment",
    render: (value: number) => `${value.toLocaleString()} 원`,
  },
  {
    title: "총 정산 금액",
    dataIndex: "totalSettlement",
    key: "totalSettlement",
    render: (value: number) => `${value.toLocaleString()} 원`,
  },
  {
    title: "총 충전 수수료",
    dataIndex: "totalPaymentFee",
    key: "totalPaymentFee",
    render: (value: number) => `${value.toLocaleString()} 원`,
  },
  {
    title: "총 정산 수수료",
    dataIndex: "totalSettlementFee",
    key: "totalSettlementFee",
    render: (value: number) => `${value.toLocaleString()} 원`,
  },
  {
    title: "보호자 잔여 포인트",
    dataIndex: "totalParentPoint",
    key: "totalParentPoint",
    render: (value: number) => `${value.toLocaleString()} 원`,
  },
  {
    title: "아이 잔여 포인트",
    dataIndex: "totalChildPoint",
    key: "totalChildPoint",
    render: (value: number) => `${value.toLocaleString()} 원`,
  },
  {
    title: "총 판매 금액",
    dataIndex: "totalAlbumAmount",
    key: "totalAlbumAmount",
    render: (value: number) => `${value.toLocaleString()} 원`,
  },
  {
    title: "총 판매 수수료",
    dataIndex: "totalAlbumAmountFee",
    key: "totalAlbumAmountFee",
    render: (value: number) => `${value.toLocaleString()} 원`,
  },
];

function OnBoards(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState<Dayjs | null>(dayjs());
  const [data, setData] = useState<IGetDash>(dashData);

  useEffect(() => {
    // If currentMonth is null, return early to avoid calling the API
    if (!currentMonth) return;

    // Set loading to true before making the API call
    setLoading(true);

    // API call with formatted currentMonth
    Apis.getDash(dayjs(currentMonth).format("YYYY-MM"))
      .then((response) => {
        console.log(response.data);
        setData((prev) => {
          return { ...prev, ...response.data };
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentMonth]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {/* Total Statistics Section */}
        <Grid item xs={12}>
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={[data]} // Wrap data in an array for Table component
            pagination={false}
            // loading={loading}
            rowKey="totalPayment"
          />
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          {/* Manual Settlement Section */}
          <Grid item xs={12} sm={6}>
            <Card>
              <Statistic
                suffix="원"
                title="수동 정산 잔여 금액"
                value={data.manualSettlementAmount}
                // loading={loading}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <MDBox display="flex" justifyContent="space-between">
                <Statistic
                  suffix="명"
                  title="수동 정산 필요 인원"
                  value={data.manualSettlementCount}
                  // loading={loading}
                />
                <MDButton
                  size="small"
                  LinkComponent={Link}
                  to="/pin-money-management/settlement-history-list?settlementType=manual&settlementStatus=pending"
                  color="info"
                  variant="contained"
                >
                  송금 대상 확인
                </MDButton>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="space-between">
              <DatePicker.MonthPicker
                size="large"
                defaultValue={dayjs()}
                format="YYYY/MM"
                onChange={(date) => {
                  console.log("Tlqkf", date);
                  if (date) {
                    setCurrentMonth(date ? date : null);
                  }
                }}
              />
              {/* <MDButton
                LinkComponent={Link}
                to="/pin-money-management/settlement-history-list"
                color="info"
                variant="contained"
              >
                수동 송금 대상
              </MDButton> */}
            </MDBox>
          </Grid>
          {/* Monthly Grouped Statistics Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <Statistic
                title={`${dayjs(currentMonth).format("YYYY/MM")} 충전 금액`}
                value={data.monthPayment}
                loading={loading}
                // prefix={<DollarCircleOutlined />}
                suffix="원"
              />
              <Statistic
                title={`${dayjs(currentMonth).format("YYYY/MM")} 충전 수수료`}
                value={data.monthPaymentFee}
                loading={loading}
                // prefix={<DollarCircleOutlined />}
                suffix="원"
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <Statistic
                title={`${dayjs(currentMonth).format("YYYY/MM")} 정산 금액`}
                // title="이번달 환전 금액"
                value={data.monthSettlement}
                loading={loading}
                // prefix={<SwapOutlined />}
                suffix="원"
              />
              <Statistic
                title={`${dayjs(currentMonth).format("YYYY/MM")} 자동 정산 수수료`}
                // title="이번달 환전 수수료"
                value={data.monthSettlementFee}
                loading={loading}
                // prefix={<SwapOutlined />}
                suffix="원"
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <Statistic
                title={`${dayjs(currentMonth).format("YYYY/MM")} 판매 금액`}
                // title="이번달 판매 금액"
                value={data.monthAlbumAmount}
                loading={loading}
                // prefix={<SwapOutlined />}
                suffix="원"
              />
              <Statistic
                title={`${dayjs(currentMonth).format("YYYY/MM")} 판매 수수료`}
                // title="이번달 판매 수수료"
                value={data.monthAlbumAmountFee}
                loading={loading}
                // prefix={<SwapOutlined />}
                suffix="원"
              />
            </Card>
          </Grid>
          <Divider />
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default OnBoards;
