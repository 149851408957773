import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";

import { IGetUsers, IHistoryAlbum } from "apis/response";

import { DatePicker, Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";

import { ColumnsType } from "antd/es/table";
import Apis from "apis/remotes";
import { formatDate } from "utils/utilDate";

const { RangePicker } = DatePicker;
function SalesHistoryList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");
  const startTime = params.get("startTime");
  const endTime = params.get("endTime");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<IHistoryAlbum[]>([]);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/pin-money-management/sales-history-list${paramsFilter({
        sort,
        order,
        search,
        page,
        startTime,
        endTime,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/pin-money-management/sales-history-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };
  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    if (dates) {
      setCurrentPage(1);
      navigate(
        `/pin-money-management/sales-history-list${paramsFilter({
          sort,
          order,
          search,
          page: 1,
          startTime: dateStrings[0],
          endTime: dateStrings[1],
        })}`
      );
    } else {
      setCurrentPage(1);
      navigate(
        `/pin-money-management/sales-history-list${paramsFilter({
          sort,
          order,
          search,
          page: 1,
        })}`
      );
    }
  };

  const tableColumn: ColumnsType<IHistoryAlbum> = [
    {
      title: "순번",
      dataIndex: "allowanceHistoryId",
      key: "allowanceHistoryId",
      align: "center",
      // sorter: (a, b) => a.transactionId - b.transactionId,
    },
    {
      title: "거래종류",
      dataIndex: "allowanceType",
      key: "allowanceType",
      align: "center",
      // filters: [
      //   { text: "미션", value: "미션" },
      //   { text: "용돈", value: "용돈" },
      //   { text: "판매", value: "판매" },
      // ],
      // onFilter: (value, record) => record.transactionType.includes(value as string),
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      align: "center",
      // render: (text, record) => `${record.senderUserId} - ${record.senderProfile}`,
    },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
      align: "center",
      // render: (text, record) => `${record.senderUserId} - ${record.senderProfile}`,
    },
    // {
    //   title: "받은사람 (아이디 - 프로필)",
    //   dataIndex: ["receiverUserId", "receiverProfile"],
    //   key: "receiver",
    //   align: "center",
    //   render: (text, record) => `${record.receiverUserId} - ${record.receiverProfile}`,
    // },
    {
      title: "판매 금액",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (amount: number) => amount.toLocaleString(),
      // sorter: (a, b) => a.sentAmount - b.sentAmount,
    },
    {
      title: "실 판매 금액",
      dataIndex: "realAmount",
      key: "realAmount",
      align: "right",
      render: (realAmount: number) => realAmount.toLocaleString(),
      // sorter: (a, b) => a.fee - b.fee,
    },
    {
      title: "판매 수수료",
      dataIndex: "albumFee",
      key: "albumFee",
      align: "right",
      render: (albumFee: number) => albumFee.toLocaleString(),
      // sorter: (a, b) => a.receivedAmount - b.receivedAmount,
    },
    {
      title: "판매 날짜",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => formatDate(text),
    },
  ];

  useEffect(() => {
    Apis.getHistoryAlbum({ page: currentPage, perPapge: 10, startTime, endTime })
      .then((resolve) => {
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, startTime, endTime]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}>
            <RangePicker onChange={handleDateRangeChange} style={{ marginRight: 10 }} />
          </Grid>
          <Grid item xs={6}>
            {/* <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            /> */}
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.allowanceHistoryId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(`/pin-money-management/mission-history-details/${record.transactionId}`);
            //     },
            //   };
            // }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default SalesHistoryList;
