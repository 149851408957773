import { useEffect, useState } from "react";
import { Card, Divider, Grid } from "@mui/material";
import { Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Apis from "apis/remotes";

function Setting(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const putAdministratorSetting = (e: any) => {
    // setLoading(true);

    const { adminSettingId, ...others } = e;

    // 문자열을 숫자로 변환 (가능한 경우)
    const transformedData = Object.fromEntries(
      Object.entries(others).map(([key, value]: any) => [
        key,
        isNaN(value) ? value : parseFloat(value),
      ])
    );

    console.log("Original Data:", e);
    console.log("Transformed Data:", transformedData);

    Apis.putSetting(adminSettingId, transformedData)
      .then((resolve) => {
        message.success("Settings updated successfully");
      })
      .catch((error) => {
        console.error(error);
        message.error("Error updating settings");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    Apis.getSettings().then((resolve) => {
      console.log(resolve);
      const feeInfo = resolve.data.data[0];
      console.log("feeInfo", feeInfo);
      form.setFieldsValue({
        ...feeInfo,
      });
    });
  }, [form]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <Form form={form} layout="vertical" onFinish={putAdministratorSetting}>
              <Form.Item hidden name="adminSettingId">
                <Input type="text" />
              </Form.Item>

              {/* 정산에 대한 컴포넌트 분리 */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Card>
                    <MDBox p={2}>
                      <Form.Item
                        name="pointFee"
                        label="충전 수수료 (%)"
                        extra="수수료 비율은 거래 금액의 일정 비율입니다. 예: 2% 입력 시 거래 금액의 2%가 수수료로 부과됩니다."
                        rules={[{ required: true, message: "충전 수수료 비율을 입력하세요." }]}
                      >
                        <Input type="number" min="0" step="0.01" />
                      </Form.Item>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card>
                    <MDBox p={2}>
                      <Form.Item
                        name="depositFee"
                        label="정산 수수료 (%)"
                        rules={[{ required: true, message: "정산 수수료 비율을 입력하세요." }]}
                      >
                        <Input type="number" min="0" step="0.01" />
                      </Form.Item>
                      <Form.Item
                        name="depositFeeMin"
                        label="정산 최소 금액 (원)"
                        rules={[{ required: true, message: "정산 최소 금액을 입력하세요." }]}
                      >
                        <Input type="number" min="0" step="1" />
                      </Form.Item>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card>
                    <MDBox p={2}>
                      <Form.Item
                        name="sellingFee"
                        label="판매 수수료 (%)"
                        rules={[{ required: true, message: "판매 수수료 비율을 입력하세요." }]}
                      >
                        <Input type="number" min="0" step="0.01" />
                      </Form.Item>
                      <Form.Item
                        name="sellingFeeMin"
                        label="판매 최소 수수료 (원)"
                        rules={[{ required: true, message: "판매 최소 수수료를 입력하세요." }]}
                      >
                        <Input type="number" min="0" step="1" />
                      </Form.Item>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <MDBox p={2}>
                      <Form.Item
                        name="url"
                        label="사용설명 동영상 링크"
                        rules={[{ required: true, message: "사용설명 동영상 링크를 입력하세요" }]}
                      >
                        <Input type="url" placeholder="https://littlebank.co.kr/dcxiuE3489" />
                      </Form.Item>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Form.Item>
                    <MDButton
                      fullWidth
                      disabled={loading}
                      type="submit"
                      color="info"
                      variant="gradient"
                    >
                      변경
                    </MDButton>
                  </Form.Item>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Setting;
