import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";

// import AntTable from "./components/table";
import { Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";
import { ColumnsType } from "antd/es/table";
import { formatDate } from "utils/utilDate";
import { formatPhone } from "utils/PhoneString";
import Apis from "apis/remotes";
import { IGetUsers, RelationType } from "apis/response";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

function MemberList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<IGetUsers[]>([]);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/member-management/member-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/member-management/member-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };

  const tableColumn: ColumnsType<IGetUsers> = [
    {
      title: "프로필 이름",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "가족관계",
      dataIndex: "relation",
      key: "relation",
      render: (relation: RelationType) => {
        switch (relation) {
          case "mom":
            return <MDBadge badgeContent="엄마" color="primary" size="md" />;
          case "dad":
            return <MDBadge badgeContent="아빠" color="info" size="md" />;
          case "child":
            return <MDBadge badgeContent="자녀" color="light" size="md" />;
          default:
            return <MDBadge badgeContent="기타" color="warning" size="md" />;
        }
      },
    },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "계정 비밀번호",
      dataIndex: "password",
      key: "password",
      // render: (text: string) => (
      //   <Input.Password visibilityToggle type="password" readOnly value={text} />
      // ),
    },
    {
      title: "휴대전화번호",
      dataIndex: "phone",
      key: "phone",
      render: (text: string) => formatPhone(text),
    },
    {
      title: "가입일",
      dataIndex: "userCreatedAt",
      key: "userCreatedAt",
      render: (text) => formatDate(text),
    },
    {
      title: "프로필생성일",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text),
    },
    {
      title: "현재포인트",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (amount: number) => amount.toLocaleString(),
    },
    {
      title: "은행명",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "계좌번호",
      dataIndex: "bankAccount",
      key: "bankAccount",
    },
  ];

  useEffect(() => {
    Apis.getUsers({ page: currentPage, perPage: 10, search, sort, order })
      .then((resolve) => {
        console.log(resolve.data);
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search, sort, order]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}>
            <MDTypography>전체 회원 수: {total.toLocaleString()}명</MDTypography>
          </Grid>
          <Grid item xs={6}>
            <Search
              maxLength={30}
              placeholder="회원의 이메일 또는 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.profileId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/member-management/member-details/${record.userId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default MemberList;
